import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js'

let bokehPass

export function postPorcessing(renderer, scene, camera, gui){
  const effectComposer = new EffectComposer(renderer)

  const renderPass = new RenderPass(scene, camera)
  effectComposer.addPass(renderPass)
  
  // Antialias
  const smaaPass = new SMAAPass()
  effectComposer.addPass(smaaPass)
  
  // Bloom
  const unrealBloomPass = new UnrealBloomPass()
  unrealBloomPass.strength = 0.11
  unrealBloomPass.radius = 0.1
  unrealBloomPass.threshold = 0
  effectComposer.addPass(unrealBloomPass)

  // Dof
  bokehPass = new BokehPass( scene, camera, {
    focus: 23.2,
    aperture: 0.005,
    maxblur: 0.02
  })
  effectComposer.addPass(bokehPass)

  // DevMode
  if(gui){
    const bloomFolder = gui.addFolder("Bloom")
    bloomFolder.add(unrealBloomPass, 'strength', 0, 2, 0.01)
    bloomFolder.add(unrealBloomPass, 'radius', 0, 2, 0.01)
    bloomFolder.add(unrealBloomPass, 'threshold', 0, 1, 0.01)

    const bokehFolder = gui.addFolder("Blur")
    bokehFolder.add(bokehPass.uniforms.focus, 'value', 0, 30, 0.0001).name('focus')
    bokehFolder.add(bokehPass.uniforms.aperture, 'value', 0, 0.03, 0.000001).name('aperture')
    bokehFolder.add(bokehPass.uniforms.maxblur, 'value', 0, 0.2, 0.0001).name('maxblur')
  }

  return effectComposer
}

export function getBohek(){
  return bokehPass
}