import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import createWorld from './threeJsScripts/world.js'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// ========= //
// Start App //
// ========= //
createWorld()

const less = document.getElementById('less')
const more = document.getElementById('more')
const count = document.getElementById('count')
let myCount = 1

less.addEventListener('click', () => {
  if(myCount > 1){
    myCount -= 1
  }
  count.innerText = String(myCount)
})
more.addEventListener('click', () => {
  if(myCount < 6){
    myCount += 1
  }
  count.innerText = String(myCount)
})

// ======= //
// End App //
// ======= //

reportWebVitals()
