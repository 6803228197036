const shader = {
  vertex: `
    uniform float uTime;
    uniform float uPixelRatio;
    uniform float uSpeed;

    attribute float uSize;

    void main()
    {

      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      modelPosition.y += sin((uTime * uSpeed) * uSize) * 2.0;
      modelPosition.x += cos((uTime * uSpeed) * uSize) * 5.0;

      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectionPosition = projectionMatrix * viewPosition;

      gl_Position = projectionPosition;
      
      gl_PointSize = uSize * uPixelRatio;
      gl_PointSize *= (1.0 / - viewPosition.z);
    }`,
  fragment: `
    uniform vec3 uRGB;

    void main()
    {
      float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
      float strength = 0.05 / distanceToCenter - 0.1;
      gl_FragColor = vec4(uRGB.r, uRGB.g, uRGB.b, strength);
    }
    `,
};

export default shader;