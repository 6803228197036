const groundShader = {
  vertex: `
    uniform float uAlpha;

    varying vec2 vUv;
    varying float vAlpha;

    void main()
    {
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectionPosition = projectionMatrix * viewPosition;
      gl_Position = projectionPosition;

      vUv = uv;
      vAlpha = uAlpha;
    }`,
  fragment: `
    varying vec2 vUv;
    varying float vAlpha;

    void main()
    {
      float alpha = 0.01 / (distance(vUv, vec2(0.5)));
      gl_FragColor = vec4(1, 1, 1, alpha * vAlpha);
    }
    `,
};

export default groundShader;