import { resetScene } from './control.js'
import { playHomeSong, restartHome } from './music.js'

export function setupHomeButton(){
  const blackButton = document.getElementById('blackButton')

  blackButton.addEventListener('click', () => {
    if(openPage){
      if(openPage ===  1)
      {
        closeMerch()
      }
      else if(openPage ===  2){
        closeMetaverse()
      }
    }

    // Update Camera
    resetScene()
  })

  const xbutton = document.getElementById('xbutton')
  xbutton.addEventListener('click', () => {
    closeMerch()
    resetScene()
  })

  const xErrorbutton = document.getElementById('xErrorbutton')
  xErrorbutton.addEventListener('click', () => {
    closeErrorWindow();
  })
}

let openPage = 0

// ===== //
// Merch //
// ===== //
export function openMerch() {
  openPage = 1

  const merchPage = document.getElementById('merch')

  merchPage.style.pointerEvents = "all"
  merchPage.style.opacity = "1"
  document.getElementsByClassName('itemPage')[0].style.opacity = 1
}

function closeMerch() {
  openPage = 0
  restartHome()
  const merchPage = document.getElementById('merch')

  merchPage.style.pointerEvents = "none"
  merchPage.style.opacity = "0"
}

// Error Window
export function openErrorWindow() {
  const errorWindowText = document.getElementById('errorWindowText');
  errorWindowText.innerHTML = `<span>ERROR <span style="color: red">666</span> - TRANSACTION FAILED</span>`;
  const errorWindow = document.getElementById('errorWindow');
  errorWindow.style.pointerEvents = "all";
  errorWindow.style.opacity = "1";
  errorWindow.style['z-index'] = "1";
  document.getElementsByClassName('itemPage')[0].style.opacity = 1;
}

function closeErrorWindow() {
  const errorWindow = document.getElementById('errorWindow');
  errorWindow.style.pointerEvents = "none";
  errorWindow.style.opacity = "0";
  errorWindow.style['z-index'] = "0";
  document.getElementsByClassName('itemPage')[0].style.opacity = 0;
}

// ===== //
// Stats //
// ===== //
let time = 0
let speed = 20
let animated = false
let timeoutList = []

export function openMetaverse(){
  document.getElementById('blackButton').style.opacity = 0
  openPage = 2
  speed = 100

  const metaversePage = document.getElementById('metaverse')

  metaversePage.style.pointerEvents = "all"
  metaversePage.style.opacity = "1"

  // Clear Timers
  for (let timer of timeoutList){
    clearTimeout(timer)
  }
  timeoutList = []

  // Animate metaverse text
  const textHolder = document.getElementById('textHolder')
  const json = require('../assets/metaverseText.json')
  time = 0

  let lineId = 0
  for (let line of json["text"]){
    // New line
    const newLine = document.createElement('p')
    textHolder.appendChild(newLine)

    // New Letter
    let lineContent = ""

    for (let letter of line){
      const localLine = lineContent + letter
      lineContent += letter

      const timeout = setTimeout(() => {
        newLine.innerText = localLine + "\u25ae"
      }, getTime())
      timeoutList.push(timeout)
    }

    // CheckLineID
    lineId += 1
    if(lineId === 3){
      time += 1000
      speed = 30
    }
    else if (lineId === 5){
      speed = 0
    }
    else if (lineId === 11){
      speed = 30
      time += 1500
    }

    let timeout = setTimeout(() => {
      newLine.innerText = lineContent
    }, getTime())
    timeoutList.push(timeout)
  }

  // Start Buzzy anim
  buzzyText()
  animated = true

  // Last Line Anim + Mint Button
  const mintBody = document.getElementById('mintBody')
  const mintButton = document.getElementById('mintButton')
  const connectButton = document.getElementById('connectButton')
  const claimButton = document.getElementById('claimButton')
  const mintText = document.getElementById('mintText')
  const redSub = document.getElementById('redSub')

  let timer = setTimeout(() => {
    mintBody.style.opacity = 1
    redSub.style.opacity = 1
    mintButton.style.pointerEvents = "all"
    connectButton.style.pointerEvents = "all"
    claimButton.style.pointerEvents = "all"

    childs.push(mintText)
  }, getTime())
  timeoutList.push(timer)
}

function getTime(){
  // Dice pause
  let pause = 0
  const dice = Math.random()
  if(dice > 0.9){
    pause = 200
  }
  else if (dice > 0.7){
    pause = 50
  }

  // Update
  time += speed + (Math.random() * speed) + pause
  return time
}

let childs = []
function buzzyText(){
  const textHolder = document.getElementById('textHolder')
  childs = []

  for (let child of textHolder.children){
    childs.push(child)
  }
  // Buzz up
  const loop = () => {
    buzz(childs)

    // Loop
    if(animated){
      setTimeout(() => {
        loop()
      }, Math.random() * 3000)
    }
  }
  loop()
}

function buzz(childs){
  let timer = 0
  // Iterate Lines
  for (let line of childs){
    const localTimer = timer
    timer += 5

    setTimeout(() => {
      line.style.opacity = 0

      setTimeout(() => {
        line.style.opacity = 0.7
      }, localTimer + 5)
    }, localTimer)
  }
}

function closeMetaverse(){
  document.getElementById('blackButton').style.opacity = 1
  openPage = 0
  const metaversePage = document.getElementById('metaverse')

  metaversePage.style.pointerEvents = "none"
  metaversePage.style.opacity = "0"

  animated = false

  const textHolder = document.getElementById('textHolder')
  setTimeout(() => {
    textHolder.innerHTML = ""
  }, 2000)

  const mintBody = document.getElementById('mintBody')
  // const mintButton = document.getElementById('mintButton')
  // const connectButton = document.getElementById('connectButton')
  // const claimButton = document.getElementById('claimButton')

  mintBody.style.opacity = 0
  // mintButton.style.pointerEvents = "none"
  // connectButton.style.pointerEvents = "none"
  // claimButton.style.pointerEvents = "none"

  playHomeSong()
}
