import gsap from 'gsap'
import { toggleRaycast } from './raycaster.js'
import { getLights } from './loadAmaya.js'
import { getRenderer } from './renderer.js'
import { getBohek } from './postProcessing.js'
import { getMaterial } from './particles.js'

let globalAmplitudes = 0.7
let amplitudes =  {
  top: 1.1 * globalAmplitudes,
  right: 1.25 * globalAmplitudes,
  bottom: 1.15 * globalAmplitudes,
  left: 1.45 * globalAmplitudes,
}

let canUpdate = false
let controlOn = true

let size = {
  width: 0,
  height: 0
}

let canvas
let sceneGroup
let camera

const baseSceneAngle = {
  x: Math.PI * 0.15,
  y: -Math.PI * 0.2,
  z: Math.PI * 0
}

let lightList
let renderer

let amayaOffset 
let transitionSpeed
let bohek
let ground
let particlesMaterial

export function iniControl(newCanvas, newCamera, newSceneGroup, newTransitionSpeed, newGround){
  canvas = newCanvas
  sceneGroup = newSceneGroup
  camera = newCamera
  transitionSpeed = newTransitionSpeed
  bohek = getBohek()
  ground = newGround
  particlesMaterial = getMaterial()

  lightList = getLights()
  renderer = getRenderer()
  
  gsap.to(camera.position, {
    z: 25,
    y: 0,
    x: amayaOffset,
    ease: "power1.inOut",
    duration: transitionSpeed
  })

  gsap.to(sceneGroup.rotation, {
    x: baseSceneAngle.x,
    y: baseSceneAngle.y,
    z: baseSceneAngle.z,
    ease: "power1.inOut",
    duration: transitionSpeed,
    onComplete:function(){
      iniEventsListeners()
      toggleRaycast(true)
    }
  })
}

let anim
let inPage = false
export function iniEventsListeners(){
  // Home Movements
  canvas.addEventListener('mousemove', event => {
    if(canUpdate && controlOn){
      canUpdate = false
      move(event.clientX, event.clientY)
    }
  })
  canvas.addEventListener('touchmove', event => {
    if(canUpdate && controlOn){
      canUpdate = false

      move(event.changedTouches[0].clientX, event.changedTouches[0].clientY)
    }
  })

  // Pages Movements
  document.addEventListener('mousemove', event => {
    if(inPage){
      canUpdate = false
      moveInPage(event.clientX, event.clientY)
    }
  })
  document.addEventListener('touchmove', event => {
    if(inPage){
      canUpdate = false

      moveInPage(event.changedTouches[0].clientX, event.changedTouches[0].clientY)
    }
  })

  // ==== //
  // Move //
  // ==== //
  function move(x, y){
    // Get Position
    let posX = (x / size.width) - 0.5
    let posY = (y / size.height) - 0.5

    // Apply amplitudes
    if(posX > 0){
      posX *= amplitudes.left
    }
    else{
      posX *= amplitudes.right
    }

    if(posY > 0){
      posY *= amplitudes.top
    }
    else{
      posY *= amplitudes.bottom
    }

    // Animate
    if(anim){
      anim.kill()
    }

    anim = gsap.to(sceneGroup.rotation, {
      y: posX + baseSceneAngle.y, 
      x: posY + baseSceneAngle.x,
      ease: "power3.out",
      duration: transitionSpeed * 0.99
    })
  }  

  function moveInPage(x, y){
    // Get Position
    let posX = (x / size.width) - 0.5
    let posY = (y / size.height) - 0.5

    // Animate
    if(anim){
      anim.kill()
    }

    anim = gsap.to(camera.rotation, {
      y: -posX * 0.5, 
      x: -posY * 0.5,
      ease: "power3.out",
      duration: transitionSpeed * 0.99
    })
  }
}

export function toggleInPage(bool){
  inPage = bool
}

export function allowUpdate(){
  canUpdate = true
}

export function updateSizes(newSize){
  size = newSize
}

export function toggleControl(bool){
  controlOn = bool
}

export function resetScene(){
  inPage = false

  // Off Home Button
  const blackButton = document.getElementById('blackButton')
  blackButton.style.pointerEvents = "none"
  blackButton.style.cursor = "default"

  // Rotate Scene
  gsap.to(sceneGroup.rotation, {
    x: baseSceneAngle.x,
    y: baseSceneAngle.y,
    z: baseSceneAngle.z,
    duration: transitionSpeed,
    ease: "power1.inOut",
    onComplete:function(){      
      // RESET END !
      controlOn = true
      toggleRaycast(true)
    }
  })

  // Zoom
  gsap.to(camera.position, {
    z: 25,
    y: 0,
    x: amayaOffset,
    ease: "power1.inOut",
    duration: transitionSpeed
  })

  // Camera Angle  
  gsap.to(camera.rotation, {
    x: 0,
    y: 0,
    ease: "power1.inOut",
    duration: transitionSpeed
  }) 

  // Reset Lights
  for (const light of lightList){    
    gsap.to(light.object.color, {
      r: light.color.r,
      g: light.color.g,
      b: light.color.b,
      ease: "power1.out",
      duration: transitionSpeed
    })
  }
  
  // Renderer Background
  gsap.to(renderer.color, {
    r: 0.68,
    g: 0.83,
    b: 0.9,
    ease: "power1.out",
    duration: transitionSpeed,
    onUpdate:function(){
      renderer.renderer.setClearColor(renderer.color, 1)
    }
  })

  // Blur  
  gsap.to(bohek.uniforms.maxblur, {
    value: 0.02,
    ease: "power3.out",
    duration: transitionSpeed
  })
  gsap.to(bohek.uniforms.focus, {
    value: 22.7,
    ease: "power3.out",
    duration: transitionSpeed
  })

  // Ground  
  gsap.to(ground.material.uniforms.uAlpha, {
    value: 1,
    ease: "power1.out",
    duration: transitionSpeed
  })

  // Particles
  gsap.to(particlesMaterial.uniforms.uRGB.value, {
    x: 1,
    y: 1,
    z: 1,
    ease: "power1.out",
    duration: transitionSpeed
  })
}

export function setAmayaOffset(newAmayaOffset){
  // If the offsetChange, animTheCamera
  if(amayaOffset !== newAmayaOffset && controlOn && camera){
    gsap.to(camera.position, {
      x: newAmayaOffset,
      ease: "power1.inOut",
      duration: transitionSpeed
    })
  }

  amayaOffset = newAmayaOffset
}