import * as THREE from 'three'

let rendererColor = new THREE.Color(0.68, 0.83, 0.90)
let renderer = null

export function createRenderer(canvas, gui){
  renderer = new THREE.WebGLRenderer({
    canvas: canvas
  })

  renderer.setClearColor(rendererColor, 1)
  renderer.shadowMap.enabled = true
  renderer.shadowMap.type = THREE.PCFSoftShadowMap
  renderer.physicallyCorrectLights = true
  
  if(gui){
    const color = {
      "color": "#ffffff"
    }
    gui.addColor(color, "color").onChange(
      function(){
        renderer.setClearColor(color['color'])
      }
    )
  }

  return renderer
}

export function getRenderer(){
  return {
    renderer: renderer,
    color: rendererColor
  }
}