import * as THREE from 'three'

const handLPos = {
  x: -3.4,
  y: 3.8,
  z: -0.4
}

const handRPos = {
  x: 3.4,
  y: 3.8,
  z: -0.4
}


export default function createButtons(){
  // ====== //
  // HitBox //
  // ====== //
  const hitBoxGeo = new THREE.BoxGeometry(1.2, 1.2, 1.2)
  const hitBoxMaterial = new THREE.MeshBasicMaterial({
    color: 0xff0000,
    transparent: true,
    opacity: 0.5
  })
  hitBoxMaterial.visible = false

  const hitHandL = new THREE.Mesh(hitBoxGeo, hitBoxMaterial)
  const hitHandR = new THREE.Mesh(hitBoxGeo, hitBoxMaterial)

  hitHandL.position.x = handLPos.x
  hitHandL.position.y = handLPos.y
  hitHandL.position.z = handLPos.z

  hitHandR.position.x = handRPos.x
  hitHandR.position.y = handRPos.y
  hitHandR.position.z = handRPos.z

  hitHandL.name = "left"
  hitHandR.name = "right"

  // ========= //
  // Triangles //
  // ========= //
  const triangleGeo = new THREE.RingGeometry(0.7, 0.8, 3)
  const ringGeo = new THREE.RingGeometry(0.3, 0.35, 25)

  const material = new THREE.MeshBasicMaterial({
    color: 0xff0000,
    transparent: true,
    opacity: 0.25
  })

  const triangleL = new THREE.Mesh(triangleGeo, material)
  const triangleR = new THREE.Mesh(triangleGeo, material)

  const triangleLGroup = new THREE.Group()
  const triangleRGroup = new THREE.Group()

  triangleL.rotation.z = Math.PI * 0.5
  triangleR.rotation.z = Math.PI * 0.5

  triangleLGroup.add(triangleL)
  triangleRGroup.add(triangleR)

  const ringL = new THREE.Mesh(ringGeo, material)
  const ringR = new THREE.Mesh(ringGeo, material)

  triangleLGroup.position.x = handLPos.x
  triangleLGroup.position.y = handLPos.y
  triangleLGroup.position.z = handLPos.z
  ringL.position.x = handLPos.x
  ringL.position.y = handLPos.y
  ringL.position.z = handLPos.z

  triangleRGroup.position.x = handRPos.x
  triangleRGroup.position.y = handRPos.y
  triangleRGroup.position.z = handRPos.z
  ringR.position.x = handRPos.x
  ringR.position.y = handRPos.y
  ringR.position.z = handRPos.z

  const meshes = {
    hitboxs: [hitHandL, hitHandR],
    rings: [triangleLGroup, triangleRGroup, ringL, ringR]
  }

  return meshes
}
