import { injected } from './blockchain/wallet/Connectors';
import { useWeb3React } from '@web3-react/core';
import { useContract } from './blockchain/ContractProvider';
import { openErrorWindow } from './threeJsScripts/pagesToggle.js'

export function Mint() {
    const { account, library: web3, activate, active } = useWeb3React(); // deactivate
    const { contract } = useContract();

    async function connect() {
        console.log('Connect');
      try {
        await activate(injected);
        console.log(active, account);
      } catch (ex) {
        console.log(ex);
        openErrorWindow();
      }
    }

    const claim = async () => {
        console.log('Claim', contract._address, account);
        if (contract && account) {
          try {
            if (await contract.methods.isWhitelistAvailableToMintForAddress(account).call()) {
              await contract.methods.mintWhitelist().send({
                from: account,
                gasLimit: 300000,
              });
            } else {
              alert('Not Whitelisted!');
            }
          } catch (error) {
            openErrorWindow();
          }
        } else {
          openErrorWindow();
        }
    }

    const handleMint = async () => {
      console.log('Mint', contract._address, account);
      if (contract && account) {
        try {
          const count = document.getElementById('count');
          const counter = parseInt(count.innerText);
          console.log(counter);
          await contract.methods.mint(counter).send({
            from: account,
            value: web3.utils.toWei((counter * 0.0666).toString(), 'ether'),
            gasLimit: 200000 * counter,
          });
        } catch (error) {
          openErrorWindow();
        }
      } else {
        openErrorWindow();
      }
    };

    return(
        // <div id="mintButton" onClick={() => (active ? handleMint() : connect())}>
        //     {/*<img src="./assets/images/mint.png" alt=""/>*/}
        //     <img src="./assets/images/connect.png" alt=""/>
        // </div>
        <div className="buttons-container">
            <div className={!active ? "" : "hideButton"} id="connectButton" onClick={() => connect()}>
                <img src="./assets/images/connect.png" alt=""/>
            </div>

            <div className={active ? "" : "hideButton"} id="mintButton" onClick={() => handleMint()}>
                <img src="./assets/images/mint.png" alt=""/>
            </div>

            <div id="claimButton" onClick={() => claim()}>
                <img src="./assets/images/claim-one-button.png" alt=""/>
            </div>
        </div>
    );
};
