import gsap from 'gsap'

const audioHome = new Audio('./assets/home.mp3')
const audioMetaverse = new Audio('./assets/metaverse.mp3')

function startLooping(audio){
  audio.addEventListener('ended', () => {
    audio.currentTime = 0
    audio.play()
  })
}
startLooping(audioHome)
startLooping(audioMetaverse)

export function restartHome(){
  audioHome.play()
}

export function playHomeSong(){
  offVolume(audioMetaverse)
  onVolume(audioHome)

  setTimeout(() => {
    audioMetaverse.pause()
    audioMetaverse.currentTime = 0
    
    audioHome.play()
  }, 2000)  
}

export function playMetaverseSong(){ 
  offVolume(audioHome)
  onVolume(audioMetaverse)

  setTimeout(() => {
    audioHome.pause()
    audioHome.currentTime = 0
    
    audioMetaverse.play()
  }, 2000)  
}

function offVolume(audio){
  gsap.to(audio, {
    volume: 0,
    duration: 2
  }) 
}

function onVolume(audio){
  gsap.to(audio, {
    volume: 1,
    duration: 2
  }) 
}