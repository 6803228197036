import * as THREE from 'three'
import gsap from 'gsap'

import { toggleControl, toggleInPage } from './control.js'
import { openMerch, openMetaverse } from './pagesToggle.js'
import { getLights } from './loadAmaya.js'
import { getRenderer } from './renderer.js'
import { playMetaverseSong } from './music.js'
import { getMaterial } from './particles.js'
import { getBohek } from './postProcessing.js'

let camera
let raycastOn = false
let sceneGroup
let lightList
let renderer
let particlesMaterial
let transitionSpeed
let bohek
let ground

export function setRaycaster(canvas, newCamera, hitboxList, newSceneGroup, newTransitionSpeed, newGroud){
  const raycaster = new THREE.Raycaster()
  const mouse = new THREE.Vector2()

  camera = newCamera
  sceneGroup = newSceneGroup
  transitionSpeed = newTransitionSpeed
  bohek = getBohek()
  ground = newGroud

  lightList = getLights()
  renderer = getRenderer()
  particlesMaterial = getMaterial()

  canvas.addEventListener('click', event => {
    if (window.innerWidth < 600) {
      raycast(event, true);
    }
    if (raycastOn) {
      raycast(event)
    }
  })

  canvas.addEventListener('mousemove', event => {
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1

    raycaster.setFromCamera( mouse, camera )
    const intersects = raycaster.intersectObjects( hitboxList );

    if (intersects.length > 0) {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }
  })

  function raycast(event, mobileView) {
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1

    raycaster.setFromCamera( mouse, camera )
    const intersects = raycaster.intersectObjects( hitboxList );

    const animateLeftPart = () => {
      document.body.style.cursor = 'default';
      animateScene(
          {
            x: 0,
            y: Math.PI * 0.5,
          },
          openMerch,
          3.85,
          1
      )
    }

    const animateRightPart = () => {
      document.body.style.cursor = 'default';
      playMetaverseSong()
      darkBackground()
      redLights()
      hideGroud()

      animateScene(
          {
            x: Math.PI * 0.2,
            y: 0,
          },
          openMetaverse,
          2.5,
          0.5
      )
    }

    if (mobileView) {
      const halfOfTheScreen = window.innerWidth / 2;
      if (event.x <= halfOfTheScreen) {
        animateLeftPart();
      } else {
        animateRightPart();
      }
    }

    if(intersects.length > 0) {
      if(intersects[0].object.name === 'left') {
        animateLeftPart();
      }
      else {
        animateRightPart();
      }
    }
  }

  function animateScene(modelAngles, openPage, cameraHeigth, openPageDelay){
    toggleControl(false)
    raycastOn = false

    setTimeout(() => {
      openPage()
    }, (transitionSpeed * 1000) * openPageDelay)

    // Scene Rotation
    gsap.to(sceneGroup.rotation, {
      x: modelAngles.x,
      y: modelAngles.y,
      duration: transitionSpeed,
      ease: "power3.inOut",
      onComplete:function(){
        toggleInPage(true)

        // Activate Home button
        const blackButton = document.getElementById('blackButton')
        blackButton.style.pointerEvents = "all"
        blackButton.style.cursor = "pointer"
      }
    })

    // Zoom
    gsap.to(camera.position, {
      z: 5,
      x: 0,
      y: cameraHeigth,
      ease: "power3.inOut",
      duration: transitionSpeed
    })

    // Blur
    gsap.to(bohek.uniforms.maxblur, {
      value: 0.1,
      ease: "power3.out",
      duration: transitionSpeed
    })
    gsap.to(bohek.uniforms.focus, {
      value: 0,
      ease: "power3.out",
      duration: transitionSpeed
    })
  }
}

export function toggleRaycast(bool){
  raycastOn = bool
}

function darkBackground(){
  // Renderer Background
  gsap.to(renderer.color, {
    r: 0,
    g: 0,
    b: 0,
    ease: "power1.out",
    duration: transitionSpeed,
    onUpdate:function(){
      renderer.renderer.setClearColor(renderer.color, 1)
    }
  })
}

function redLights(){
  // Light
  for (const light of lightList){
    gsap.to(light.object.color, {
      r: 0.2,
      g: 0,
      b: 0,
      ease: "power1.out",
      duration: transitionSpeed
    })
  }

  // Particles
  gsap.to(particlesMaterial.uniforms.uRGB.value, {
    x: 1,
    y: 0.52,
    z: 0.05,
    ease: "power1.out",
    duration: transitionSpeed
  })
}

function hideGroud(){
  gsap.to(ground.material.uniforms.uAlpha, {
    value: 0,
    ease: "power1.out",
    duration: transitionSpeed
  })
}
