import * as THREE from 'three'
import shader from './shader/particles.js'

let material

export function createParticles(){

  const count = 1000;
  const expand = 50;

  const positions = new Float32Array(count * 3)
  const sizes = new Float32Array(count * 3)
  for (let i = 0; i < count * 3; i++) {
    positions[i] = (Math.random() - 0.5) * expand
    sizes[i] = Math.random() * 1000
  }

  // Geometry
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3))
  geometry.setAttribute("uSize", new THREE.BufferAttribute(sizes, 3))

  // Material
  material = new THREE.ShaderMaterial({
    uniforms: {
      uTime: { value: 0 },
      uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
      uSpeed: { value: 0.0004 },
      uRGB: { value: new THREE.Vector3(1, 1, 1)}
    },
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    vertexShader: shader.vertex,
    fragmentShader: shader.fragment
  });

  const points = new THREE.Points(geometry, material);

  return points;
}

export function getMaterial(){
  return material
}