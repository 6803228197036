import './App.css';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import MetamaskProvider from './blockchain/MetamaskProvider';
import { Mint } from './Mint';
import ContractProvider from './blockchain/ContractProvider';

function App() {
  function getLibrary(provider) {
    return new Web3(provider);
  }

  return (
    <div id="App">
      <canvas id="webgl"></canvas>
      {/* Welcome */}
      <div id="enter">
        <div id="enterButton">
          <img src="./assets/images/enterbutton.png" alt=""/>
        </div>
      </div>
      {/* HomeButton */}
      <div id="homeButton">
        <div id="blackButton">
          <img src="./assets/images/home.png" alt=""/>
        </div>
        <div>
          <img src="./assets/images/homeL.png" alt=""/>
        </div>
      </div>
      {/* Merch */}
      <div id="merch">
        <div className="itemPage">
          <div id="xButtonHolder">
            <div id="xbutton">
              <img src="./assets/images/x.png" alt="" />
            </div>
          </div>
          <p id="merchText">
            Merchandise will be unlocked when Amaya has progressed.
          </p>
        </div>
      </div>
      {/* Error Window */}
      <div id="errorWindow">
        <div className="itemPage">
          <div id="xButtonHolder">
            <div id="xErrorbutton">
              <img src="./assets/images/x.png" alt="" />
            </div>
          </div>
          <p id="errorWindowText" />
        </div>
      </div>
      {/* Metaverse */}
      <div id="metaverse">
        <div id="textHolder">
        </div>
        <div id="mintBody" className="slideUp">
          <p id="mintText">
            "WITH THIS OFFERING I INVOKE THEE"
          </p>
          <div>
            <div id="selecterHolder">
              <p id="less">
                -
              </p>
              <p id="count">
                1
              </p>
              <p id="more">
                +
              </p>
              <Web3ReactProvider getLibrary={getLibrary}>
              <MetamaskProvider>
              <ContractProvider>
                <Mint />
                </ContractProvider>
              </MetamaskProvider>
              </Web3ReactProvider>
            </div>
            <p id="redSub">
              Cost .0666 per Sigil
            </p>
          </div>
        </div>
        <div id="bune">
          <div id="demonBody">
            <div id="demon">
              <img src="./assets/images/demon.jpg" alt=""/>
            </div>
            <div id="attributes">
              <h2>
                Bune
              </h2>
              <p id="attributesTitle">
                Attributes
              </p>
              <p>
                - Demonic<br/>
                - King
              </p>
            </div>
          </div>
          <div id="opensea">
            <img src="./assets/images/opensea.png" alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
