import React, { useState, useEffect, useContext } from "react";
import DigitalSigil from "./DigitalSigil.json";
import { useWeb3React } from '@web3-react/core';

export function useContract() {
    const w = useContext(ContractContext);
    if (!w) {
        throw new Error('Missing contract context');
    }
    return w;
}

const ContractContext = React.createContext(null);

export const NFT_CONTRACT_ADDRESS = "0x23B7b414cAC00d5FF8eC4D2Acf6a95C88e89cD34";

export default function ContractProvider(props) {
    const { active, library } = useWeb3React();

    const [contract, setContract] = useState(null);

    useEffect(() => { 
        if (active) {
            const nftContract = new library.eth.Contract(DigitalSigil.abi, NFT_CONTRACT_ADDRESS);
            setContract(nftContract);
        }
    }, [active, library, setContract]);

    return (
        <ContractContext.Provider value={{ contract }}>
            {props.children}
        </ContractContext.Provider>
    )
}