import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

const lightsList = []

export function loadAmaya(gui, callBack){
  // ====== //
  // Loader //
  // ====== //
  const dracoLoader = new DRACOLoader()
  dracoLoader.setDecoderPath('./assets/draco/')

  const loader = new GLTFLoader()
  loader.setDRACOLoader(dracoLoader)

  // ===== //
  // Model //
  // ===== //
  let amaya
  let materialStack = []
  loader.load('./assets/AmayaDraco.glb', gltf => {
    amaya = gltf.scene

    amaya.traverse((child) => {
      if(child instanceof THREE.Mesh) {
        // Sha
        child.castShadow = true
        child.receiveShadow = true

        // Material
        child.material = new THREE.MeshStandardMaterial({
          side: THREE.FrontSide,
          transparent: true,
          opacity: 0,
          map: child.material.map
        })

        materialStack.push(child.material)
      }
      else if (child instanceof THREE.SpotLight || child instanceof THREE.PointLight){
        if(!child.name.includes('hand')){
          child.castShadow = true
          child.shadow.normalBias = 0.035
        }

        lightsList.push({
          object: child,
          intensity: child.intensity,
          color: {
            r: child.color.r,
            g: child.color.g,
            b: child.color.b
          }
        })
      }
    })

    // ======= //
    // DevMode //
    // ======= //
    if(gui){
      const lightBias = {
        value: 2
      }

      const lightFolder = gui.addFolder('Lights')
      lightFolder.add(lightBias, 'value', 0, 0.1, 0.01).name('NormalBias').onChange(updateBias)

      function updateBias(biasValue){
        for (let light of lightsList){
          light.object.shadow.normalBias = biasValue
        }
      }

      for (let light of lightsList){
        lightFolder.add(light.object, 'intensity', 0, light.object.intensity * 2, 0.01).name(light.object.name)
      }
    }

    callBack(amaya, materialStack)
  })
}

export function getLights(){
  return lightsList
}

export function pushLights(light){
  lightsList.push({
    object: light,
    intensity: light.intensity,
    color: {
      r: light.color.r,
      g: light.color.g,
      b: light.color.b
    }
  })
}
