import * as THREE from 'three'

import dat from 'dat.gui'
import gsap from 'gsap'

import { postPorcessing, getBohek } from './postProcessing.js'
import { iniControl, allowUpdate, updateSizes, setAmayaOffset } from './control.js'
import { setupHomeButton } from './pagesToggle.js'
import createButtons from './handsButton.js'
import { setRaycaster } from './raycaster.js'
import { createParticles } from './particles.js'
import { loadAmaya, pushLights } from './loadAmaya.js'
import { createRenderer } from './renderer.js'
import groundShader from './shader/ground.js'
import { ShaderMaterial } from 'three'
import { playHomeSong } from './music.js'

export default function createWorld(){
  let gui = null
  if(window.location.hash.match('dev')){
    gui = new dat.GUI()
  }

  const scene = new THREE.Scene()
  const canvas = document.getElementById('webgl')
  const transitionSpeed = 7
  setupHomeButton()
  
  // ====== //
  // Camera //
  // ====== //
  const camera = new THREE.PerspectiveCamera(50, 0, 0.01, 1000)
  camera.position.z = 35
  
  scene.add(camera)
  
  // ======== //
  // Renderer //
  // ======== //
  const renderer = createRenderer(canvas, gui)
  
  // ===== //
  // Model //
  // ===== //
  const buttons = createButtons()
  const particles = createParticles()

  loadAmaya(gui, modelLoadedCallBack)

  const ground = new THREE.Mesh(
    new THREE.PlaneGeometry(250, 250),
    new ShaderMaterial({
      vertexShader: groundShader.vertex,
      fragmentShader: groundShader.fragment,
      transparent: true,
      uniforms: {
        uAlpha: { value: 1 }
      }
    })
  )
  ground.rotation.x = - Math.PI * 0.5
  ground.position.y = -5.5
  
  // ====== //
  // Lights //
  // ====== //
  const light = new THREE.AmbientLight(0xffffff, 1)
  scene.add(light)
  pushLights(light)

  // ============ //
  // Model Loaded //
  // ============ //
  const sceneGroup = new THREE.Group()
  scene.add(sceneGroup)
  sceneGroup.rotation.y = -Math.PI * 0.2 * 3

  sceneGroup.add(ground)
  sceneGroup.add(particles)

  let amayaMaterial  
  const enterButton = document.getElementById('enterButton')
  const enter = document.getElementById('enter')

  function modelLoadedCallBack(amaya, materialStack){
    amayaMaterial = materialStack

    // Populate Scene group
    sceneGroup.add(amaya)
    enterButton.style.pointerEvents = "all"
    enterButton.style.opacity = 1
    enterButton.style.cursor = "pointer"
  }

  enterButton.addEventListener('click', () => {
    enterButton.style.pointerEvents = "none"
    document.getElementById('homeButton').style.opacity = 0.9

    // Animate Camera
    iniScene()
    sceneGroup.add(buttons['hitboxs'][0])
    sceneGroup.add(buttons['hitboxs'][1])
    for (let ring of buttons['rings']){
      sceneGroup.add(ring)
    }

    // Display Amaya
    for (let material of amayaMaterial){
      gsap.to(material, {
        opacity: 1,
        duration: transitionSpeed,
        ease: "power1.out",
      })
    }

    // Hide button
    enter.style.opacity = 0
    setTimeout(() => {
      enter.style.display = "none"
    }, 4000)
  })

  function iniScene(){
    // ===== //
    // Music //
    // ===== //    
    playHomeSong()

    // ======= //
    // Control //
    // ======= //
    iniControl(canvas, camera, sceneGroup, transitionSpeed, ground)

    // ======= //
    // Raycaster//
    // ======= //
    setRaycaster(canvas, camera, buttons.hitboxs, sceneGroup, transitionSpeed, ground)
  }
  
  // ============== //
  // PostProcessing //
  // ============== //
  const effectComposer = postPorcessing(renderer, scene, camera, gui)

  // ==== //
  // Tick //
  // ==== //
  const date = new THREE.Clock()
  const tick = () => {    
    // Particles
    const elapsed = date.getElapsedTime()

    particles.material.uniforms.uTime.value = elapsed
    particles.rotation.y = elapsed * 0.05

    // Update Ring
    for (let ring of buttons.rings){
      ring.lookAt(camera.position)
    }

    // Control
    allowUpdate()

    // Render
    effectComposer.render(scene, camera)
  
    // Loop
    window.requestAnimationFrame(tick)
  }
  tick()
  
  const bohek = getBohek()
  function resize(){
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }
  
    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
  
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    effectComposer.setSize(sizes.width, sizes.height)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  
    // CameraControl sizes
    updateSizes(sizes)

    // Offset Amaya
    if(sizes.width > sizes.height){
      setAmayaOffset(5)
      bohek.uniforms.aperture.value = 0.005
      bohek.uniforms.focus.value = 23.2
    }
    else{
      setAmayaOffset(0)
      bohek.uniforms.aperture.value = 0.02
      bohek.uniforms.focus.value = 23
    }
  }
  resize()
  
  window.addEventListener('resize', () => {
    resize()
  })
}